.react-app ._cookieBannerContainer_128y4_1 {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: var(--space-big);
  background-color: var(--ff-color-grey-600);
  box-sizing: border-box;
}
.react-app ._cookieBannerContainer_128y4_1 p {
  margin: 0;
}
.react-app ._cookieBannerContainer_128y4_1 ._buttonCol_128y4_12 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: calc(var(--space-default) / 2);
}
.react-app ._cookieBannerContainer_128y4_1 ._textCol_128y4_18 {
  flex: 1;
  padding-right: calc(var(--space-default) / 2);
}
.react-app ._cookieBannerContainer_128y4_1._small_128y4_22 {
  padding: var(--space-default);
}
.react-app ._cookieBannerContainer_128y4_1._small_128y4_22 ._flexCols_128y4_25 {
  flex-direction: column;
}
.react-app ._cookieBannerContainer_128y4_1._small_128y4_22 ._textCol_128y4_18 {
  margin-bottom: var(--space-default);
  margin-right: 0;
  padding-right: 0;
}
.react-app ._cookieBannerContainer_128y4_1._small_128y4_22 ._buttonCol_128y4_12 {
  justify-content: flex-start;
  padding-left: 0;
}
.react-app ._cookieBannerContainer_128y4_1 ._linkText_128y4_37 {
  text-decoration: underline;
}
.react-app ._cookieBannerContainer_128y4_1 ._flexCols_128y4_25 {
  display: flex;
}
.react-app ._cookieBannerContainer_128y4_1 ._noEndMargin_128y4_43 {
  margin-right: 0;
}
.react-app ._cookieBannerContainer_128y4_1 ._cookieText_128y4_46 p {
  display: inline;
}

.react-app ._cookiesPolicyModal_128y4_50 p {
  margin: 0;
}
.react-app ._cookiesPolicyModal_128y4_50 ._noEndMargin_128y4_43 {
  margin-right: 0;
}