.react-app ._container_1l9ia_1 {
  padding: var(--space-default);
  border-bottom: var(--border-base-lighter);
}

.react-app ._paddingFields_1l9ia_6 {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--space-less);
}

.react-app ._paddingField_1l9ia_6 {
  flex-basis: 50%;
}