.react-app ._container_7qypr_1 {
  padding: var(--space-default) 0 0;
}

.react-app ._paddingFields_7qypr_5 {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--space-less);
}

.react-app ._paddingField_7qypr_5 {
  flex-basis: 50%;
}