.react-app ._container_1n9jn_1 {
  padding: var(--space-small) var(--space-default);
  width: 100%;
  display: flex;
  border-bottom: var(--border-base-lighter);
  justify-content: space-between;
  box-sizing: border-box;
}

.react-app ._leftActions_1n9jn_10,
.react-app ._devices_1n9jn_11 {
  display: flex;
  gap: var(--space-small);
  align-items: center;
}

.react-app ._undoRedo_1n9jn_17 {
  display: flex;
  gap: var(--space-tiny);
}

.react-app ._device_1n9jn_11 {
  margin: 0;
}