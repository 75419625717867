.react-app ._leftmostColumn_rt7a3_1 {
  padding-left: var(--space-more) !important;
}

.react-app ._amountColumn_rt7a3_5 {
  text-align: right !important;
  width: 136px;
}

.react-app ._actionsColumn_rt7a3_10 {
  display: flex;
  align-items: center;
  gap: var(--space-less);
}

.react-app ._actionsBtn_rt7a3_16 {
  display: flex;
  align-items: center;
}

.react-app ._actionsLink_rt7a3_21._disabled_rt7a3_21:hover {
  background-color: initial;
  color: var(--ff-color-text-base-lightest) !important;
}

.react-app ._amountBeforeDiscount_rt7a3_26 {
  text-decoration: line-through;
}

.react-app ._externalPayerData_rt7a3_30 {
  display: flex;
  gap: var(--space-tiny);
  margin-top: var(--space-small);
}