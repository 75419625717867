.react-app ._noFeesContainer_16zrl_1 {
  display: flex;
  flex-direction: column;
  padding: var(--space-big);
  text-align: center;
  gap: var(--space-tiny);
}

.react-app ._addFeeBtnContainer_16zrl_9 {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  border-top: var(--border-base-lighter);
  padding: var(--space-small) 0;
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._planStatusContainer_16zrl_19 {
  display: flex;
  justify-content: space-between;
  padding: var(--space-less) var(--space-more);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.react-app ._planStatusContainer_16zrl_19._valid_16zrl_26 {
  background-color: var(--ff-bg-success-lightest);
}
.react-app ._planStatusContainer_16zrl_19._invalid_16zrl_29 {
  background-color: var(--ff-bg-alert-lightest);
}