.react-app ._table_u5757_1 {
  width: 100%;
  border: none;
  table-layout: fixed;
}
.react-app ._table_u5757_1 td:nth-child(1) {
  width: 24px;
}
.react-app ._table_u5757_1,
.react-app ._table_u5757_1 thead,
.react-app ._table_u5757_1 tr,
.react-app ._table_u5757_1 th,
.react-app ._table_u5757_1 td {
  border-collapse: collapse;
  vertical-align: middle;
}

.react-app ._header_u5757_18 {
  border: none;
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._headerItem_u5757_23 {
  border: none;
  padding: var(--space-small) 0;
  padding-right: var(--space-more);
  text-align: left;
  vertical-align: top;
  box-sizing: content-box;
}

.react-app ._headerItem_u5757_23._mobile_u5757_32 {
  padding: var(--space-small) var(--space-less);
}

.react-app ._row_u5757_36 {
  border-top: 1px solid transparent;
  border-bottom: var(--border-base-lighter);
}

.react-app ._row_u5757_36._borderless_u5757_41 {
  border-bottom: none;
}

.react-app ._tableData_u5757_45 {
  border: none;
  padding: 0;
  padding: var(--space-more) 0;
  padding-right: var(--space-more);
  text-align: left;
  vertical-align: top;
}

.react-app ._leftmostColumn_u5757_54 {
  padding-left: var(--space-more);
}

.react-app ._actionsColumn_u5757_58 {
  width: 120px;
}
.react-app ._actionsColumn_u5757_58._bigger_u5757_61 {
  width: 230px;
}

.react-app ._amountColumn_u5757_65 {
  text-align: right !important;
  width: 136px;
}

.react-app ._arrowIconColumn_u5757_70 {
  width: 16px;
}
.react-app ._arrowIconColumn_u5757_70 ._placeholder_u5757_73 {
  width: 16px;
}

.react-app ._tableData_u5757_45._mobile_u5757_32 {
  padding: var(--space-default) var(--space-less);
}