.react-app ._container_1s1dz_1 {
  padding: var(--space-default);
  border-bottom: var(--border-base-lighter);
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--space-less);
}

.react-app ._paddingField_1s1dz_9 {
  flex-basis: 50%;
}