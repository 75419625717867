.react-app ._editor_1ojh7_1 {
  height: 478px;
  outline: none;
  white-space: normal;
  padding: var(--space-small) var(--space-less);
  background: var(--ff-bg-base-lightest);
  border: var(--border-base-light);
  box-shadow: var(--shadow-inset);
  border-radius: var(--border-radius-default);
  color: var(--ff-color-text-base-darkest);
  text-align: left;
  overflow: auto;
}
.react-app ._editor_1ojh7_1 [contenteditable="true"] {
  outline: none;
}
.react-app ._editor_1ojh7_1 .tiptap {
  min-height: 100%;
}
.react-app ._editor_1ojh7_1 a {
  text-decoration: underline;
}

.react-app ._container_1ojh7_24 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 73px);
  padding: 20px;
  overflow: auto;
}

.react-app ._editorContainer_1ojh7_33 {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  gap: var(--space-less);
}