.react-app ._badgesAligner_2taam_1 {
  display: inline-flex;
  flex-wrap: wrap;
}

.react-app ._badgeContainer_2taam_6 {
  display: inline-flex;
  flex-wrap: nowrap;
}

.react-app ._badgeSpacing_2taam_11 {
  margin-right: var(--space-tiny);
}