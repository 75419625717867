.react-app ._topContainer_1395l_1 {
  background-color: var(--ff-bg-base-lighter);
  height: 56px;
  padding: var(--space-default);
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.react-app ._backButton_1395l_10 {
  display: flex;
  cursor: pointer;
  gap: var(--space-small);
  align-items: center;
  width: 55px;
}

.react-app ._container_1395l_18 {
  height: calc(100vh - 130px);
  overflow: auto;
  z-index: 1;
}