.react-app ._container_32k6j_1 {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--space-small);
}

.react-app ._editorMainActions_32k6j_8,
.react-app ._undoRedoActions_32k6j_9 {
  display: flex;
  align-items: center;
  gap: var(--space-small);
  flex-wrap: wrap;
}

.react-app ._mergeTagButton_32k6j_16 {
  color: var(--ff-color-text-base-darkest);
}