.react-app ._container_muhzv_1 {
  max-width: 850px;
}
.react-app ._container_muhzv_1 ._header_muhzv_4 {
  margin-top: var(--space-default);
}
.react-app ._container_muhzv_1 ._customTabName_muhzv_7 {
  margin-top: var(--space-less);
  margin-bottom: var(--space-tiny);
}
.react-app ._container_muhzv_1 ._applicPaymentCard_muhzv_11 {
  margin-top: var(--space-more);
  margin-bottom: var(--space-more);
}