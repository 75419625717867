.react-app ._container_1m8wf_1 {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border-left: 4px solid transparent;
  cursor: pointer;
  box-sizing: border-box;
  border-right: 1px solid transparent;
}

.react-app ._active_1m8wf_15,
.react-app ._container_1m8wf_1:hover {
  background-color: var(--ff-bg-primary-lightest);
  border-left: 4px solid var(--ff-color-primary-600);
  border-right: var(--border-base-lighter);
}

.react-app ._active_1m8wf_15 {
  cursor: default;
}